<template>
  <div class="flex items-center flex-col">
    <div v-if="isLoading" class="items-center flex text-base justify-center">
      <spinner color-scheme="primary" />
      <span>{{ $t('CAMPAIGN_MESSAGE.LIST.LOADING_MESSAGE') }}</span>
    </div>
    <div v-else class="w-full px-16">
      <empty-state v-if="showEmptyResult" :title="emptyMessage" />
      <div v-else class="w-full px-16">
        <campaign-message-card
          v-for="campaignMessage in campaignMessages"
          :key="campaignMessage.id"
          :campaign-message="campaignMessage"
          @edit="campaignMessage => $emit('edit', campaignMessage)"
          @delete="campaignMessage => $emit('delete', campaignMessage)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import CampaignMessageCard from './CampaignMessageCard.vue';

export default {
  components: {
    EmptyState,
    Spinner,
    CampaignMessageCard,
  },
  props: {
    campaignMessages: {
      type: Array,
      default: () => [],
    },
    showEmptyResult: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentCampaignMessageId() {
      return this.$route.params.campaignMessageId;
    },
    emptyMessage() {
      if (this === undefined) {
        return this.$t('CAMPAIGN_MESSAGE.404');
      }

      return this.campaignMessages.length
        ? this.$t('CAMPAIGN_MESSAGE.404')
        : this.$t('CAMPAIGN_MESSAGE.NOT_FOUND');
    },
  },
};
</script>
