<template>
  <div class="h-auto overflow-auto flex flex-col">
    <omfy-modal-header
      :header-title="$t('CAMPAIGN_MESSAGE.ADD.TITLE')"
      :header-content="$t('CAMPAIGN_MESSAGE.ADD.DESC')"
    />
    <form class="flex flex-col w-full" @submit.prevent="addCampaignMessage">
      <div class="w-full">
        <omfy-input
          v-model="title"
          :label="$t('CAMPAIGN_MESSAGE.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="
            $v.title.$error ? $t('CAMPAIGN_MESSAGE.ADD.FORM.TITLE.ERROR') : ''
          "
          :placeholder="$t('CAMPAIGN_MESSAGE.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />

        <label :class="{ error: $v.description.$error }">
          {{ $t('CAMPAIGN_MESSAGE.ADD.FORM.DESCRIPTION.LABEL') }}
          <textarea
            v-model="description"
            rows="5"
            type="text"
            :placeholder="
              $t('CAMPAIGN_MESSAGE.ADD.FORM.DESCRIPTION.PLACEHOLDER')
            "
            @blur="$v.description.$touch"
          />
          <span v-if="$v.description.$error" class="message">
            {{ $t('CAMPAIGN_MESSAGE.ADD.FORM.DESCRIPTION.ERROR') }}
          </span>
        </label>

        <label>
          <input
            v-model="active"
            type="checkbox"
            value="active"
            name="active"
          />
          {{ $t('CAMPAIGN_MESSAGE.ADD.FORM.ACTIVE') }}
        </label>
      </div>

      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <omfy-button :is-loading="uiFlags.isCreating">
          {{ $t('CAMPAIGN_MESSAGE.ADD.CREATE_BUTTON_TEXT') }}
        </omfy-button>
        <omfy-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN_MESSAGE.ADD.CANCEL_BUTTON_TEXT') }}
        </omfy-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import { CAMPAIGN_MESSAGES_EVENTS } from '../../../helper/AnalyticsHelper/events';

export default {
  mixins: [alertMixin],
  data() {
    return {
      title: '',
      description: '',
      active: false,
    };
  },

  validations() {
    const commonValidations = {
      title: {
        required,
      },
      description: {
        required,
      },
    };
    return {
      ...commonValidations,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaignMessages/getUIFlags',
    }),
  },
  mounted() {
    this.$track(CAMPAIGN_MESSAGES_EVENTS.OPEN_NEW_CAMPAIGN_MESSAGE_MODAL);
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    onChange(value) {
      this.scheduledAt = value;
    },
    getCampaignMessageDetails() {
      let campaignMessageDetails = null;
      campaignMessageDetails = {
        title: this.title,
        description: this.description,
        active: this.active,
      };
      return campaignMessageDetails;
    },
    async addCampaignMessage() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        const campaignMessageDetails = this.getCampaignMessageDetails();
        await this.$store.dispatch(
          'campaignMessages/create',
          campaignMessageDetails
        );

        // tracking this here instead of the store to track the type of campaign
        this.$track(CAMPAIGN_MESSAGES_EVENTS.CREATE_CAMPAIGN_MESSAGE);

        this.showAlert(this.$t('CAMPAIGN_MESSAGE.ADD.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('CAMPAIGN_MESSAGE.ADD.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-omfy-style {
  height: 5rem;
}
</style>
