import Index from './Index.vue';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/campaign_messages'),
    name: 'campaign_messages',
    roles: ['administrator'],
    component: Index,
    props: {
      headerTitle: 'CAMPAIGN_MESSAGE.HEADER',
      icon: 'folder-open',
    },
  },
];
