import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CampaignMessagesAPI from '../../api/campaignMessages';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { CAMPAIGN_MESSAGES_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isFetchingItem: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCampaignMessages: _state => {
    return _state.records;
  },

  getCampaignMessagesActives($state) {
    return $state.records.filter(item => item.active === true);
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isFetching: true });
    try {
      const response = await CampaignMessagesAPI.get();
      commit(types.SET_CAMPAIGN_MESSAGES, response.data);
    } catch (error) {
      // teste
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isFetching: false });
    }
  },
  actives: async ({ commit }) => {
    commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isFetching: true });
    try {
      const response = await CampaignMessagesAPI.getCampaignMessagesActives();
      commit(types.SET_CAMPAIGN_MESSAGES, response.data);
    } catch (error) {
      // teste
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createCampaignMessage({ commit }, campaignMessageObj) {
    commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isCreating: true });
    try {
      const response = await CampaignMessagesAPI.create(campaignMessageObj);
      commit(types.ADD_CAMPAIGN_MESSAGE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isUpdating: true });
    try {
      const response = await CampaignMessagesAPI.update(id, updateObj);
      AnalyticsHelper.track(CAMPAIGN_MESSAGES_EVENTS.UPDATE_CAMPAIGN_MESSAGE);
      commit(types.EDIT_CAMPAIGN_MESSAGE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isDeleting: true });
    try {
      await CampaignMessagesAPI.delete(id);
      AnalyticsHelper.track(CAMPAIGN_MESSAGES_EVENTS.DELETE_CAMPAIGN_MESSAGE);
      commit(types.DELETE_CAMPAIGN_MESSAGE, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CAMPAIGN_MESSAGE_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_CAMPAIGN_MESSAGE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_CAMPAIGN_MESSAGE]: MutationHelpers.create,
  [types.SET_CAMPAIGN_MESSAGES]: MutationHelpers.set,
  [types.EDIT_CAMPAIGN_MESSAGE]: MutationHelpers.update,
  [types.DELETE_CAMPAIGN_MESSAGE]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
