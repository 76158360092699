<template>
  <div class="w-full flex flex-row">
    <iframe
      :src="flowViewSrc"
      title="Flow View"
    >
    </iframe>
  </div>
</template>

<script>
import Flows from '../../../api/flows';

export default {
  components: {},
  mixins: [],
  data() {
    return { flowViewSrc: "" };
  },
  created: function () {
    Flows.authenticateFlow().then(response => {
      const flowUrl = window.location.origin + "/intech/" + response.data.account_id + "/?access_token=" + response.data.access_token
      this.flowViewSrc = flowUrl;
    }).catch(err => console.log(err));
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style>
iframe {
  height: 99.5%;
  width: 100%;
  border: none;
}
.content-box {
  padding: 0px;
}
</style>
