import Flow from './Flow.vue';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
  {
    path: frontendURL('accounts/:accountId/flow/dashboard'),
    name: 'flow_dashboard',
    roles: ['administrator'],
    component: Flow,
    props: {},
  },
];
