<template>
  <div class="h-auto overflow-auto flex flex-col">
    <omfy-modal-header :header-title="pageTitle" />
    <form class="flex flex-col w-full" @submit.prevent="editCampaign">
      <div class="w-full">
        <omfy-input
          v-model="title"
          :label="$t('CAMPAIGN_MESSAGE.ADD.FORM.TITLE.LABEL')"
          type="text"
          :class="{ error: $v.title.$error }"
          :error="
            $v.title.$error ? $t('CAMPAIGN_MESSAGE.ADD.FORM.TITLE.ERROR') : ''
          "
          :placeholder="$t('CAMPAIGN_MESSAGE.ADD.FORM.TITLE.PLACEHOLDER')"
          @blur="$v.title.$touch"
        />

        <label :class="{ error: $v.description.$error }">
          {{ $t('CAMPAIGN_MESSAGE.ADD.FORM.DESCRIPTION.LABEL') }}
          <textarea
            v-model="description"
            rows="5"
            type="text"
            :placeholder="
              $t('CAMPAIGN_MESSAGE.ADD.FORM.DESCRIPTION.PLACEHOLDER')
            "
            @blur="$v.description.$touch"
          />
          <span v-if="$v.description.$error" class="message">
            {{ $t('CAMPAIGN_MESSAGE.ADD.FORM.DESCRIPTION.ERROR') }}
          </span>
        </label>

        <label>
          <input
            v-model="active"
            type="checkbox"
            value="active"
            name="active"
          />
          {{ $t('CAMPAIGN_MESSAGE.ADD.FORM.ACTIVE') }}
        </label>
      </div>
      <div class="flex flex-row justify-end gap-2 py-2 px-0 w-full">
        <omfy-button :is-loading="uiFlags.isCreating">
          {{ $t('CAMPAIGN_MESSAGE.EDIT.UPDATE_BUTTON_TEXT') }}
        </omfy-button>
        <omfy-button variant="clear" @click.prevent="onClose">
          {{ $t('CAMPAIGN_MESSAGE.ADD.CANCEL_BUTTON_TEXT') }}
        </omfy-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    selectedCampaignMessage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: '',
      description: '',
      active: false,
    };
  },
  validations: {
    title: {
      required,
    },
    description: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaignMessages/getUIFlags',
    }),
    pageTitle() {
      return `${this.$t('CAMPAIGN_MESSAGE.EDIT.TITLE')} - ${
        this.selectedCampaignMessage.title
      }`;
    },
  },
  mounted() {
    this.setFormValues();
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    setFormValues() {
      const { title, description, active } = this.selectedCampaignMessage;
      this.title = title;
      this.description = description;
      this.active = active;
    },
    async editCampaign() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      try {
        await this.$store.dispatch('campaignMessages/update', {
          id: this.selectedCampaignMessage.id,
          title: this.title,
          description: this.description,
          active: this.active,
        });
        this.showAlert(this.$t('CAMPAIGN_MESSAGE.EDIT.API.SUCCESS_MESSAGE'));
        this.onClose();
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN_MESSAGE.EDIT.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .ProseMirror-omfy-style {
  height: 5rem;
}
</style>
