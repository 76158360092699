<template>
  <div class="flex-1 overflow-auto">
    <campaign-messages-header
      :header-title="pageTitle"
      this-selected-campaign-message-id=""
    />
    <campaign-messages-table
      :campaign-messages="campaignMessages"
      :show-empty-result="showEmptyResult"
      :is-loading="uiFlags.isFetching"
      @edit="openEditPopup"
      @delete="openDeletePopup"
    />
    <omfy-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-campaign-message
        :selected-campaign-message="selectedCampaignMessage"
        @on-close="hideEditPopup"
      />
    </omfy-modal>
    <omfy-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('CAMPAIGN_MESSAGE.DELETE.CONFIRM.TITLE')"
      :message="$t('CAMPAIGN_MESSAGE.DELETE.CONFIRM.MESSAGE')"
      :confirm-text="$t('CAMPAIGN_MESSAGE.DELETE.CONFIRM.YES')"
      :reject-text="$t('CAMPAIGN_MESSAGE.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import CampaignMessagesHeader from './Header.vue';
import CampaignMessagesTable from './CampaignMessagesTable.vue';
import EditCampaignMessage from './EditCampaignMessage.vue';
export default {
  components: {
    CampaignMessagesHeader,
    CampaignMessagesTable,
    EditCampaignMessage,
  },
  mixins: [alertMixin],
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showEditPopup: false,
      selectedCampaignMessage: {},
      showDeleteConfirmationPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'campaignMessages/getUIFlags',
    }),
    campaignMessages() {
      return this.$store.getters['campaignMessages/getCampaignMessages'];
    },
    showEmptyResult() {
      const hasEmptyResults =
        !this.uiFlags.isFetching && this.campaignMessages.length === 0;
      return hasEmptyResults;
    },
    pageTitle() {
      return this.$t('CAMPAIGN_MESSAGE.HEADER');
    },
  },
  methods: {
    openEditPopup(campaignMessage) {
      this.selectedCampaignMessage = campaignMessage;
      this.showEditPopup = true;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    openDeletePopup(campaignMessage) {
      this.showDeleteConfirmationPopup = true;
      this.selectedCampaignMesssage = campaignMessage;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      const { id } = this.selectedCampaignMesssage;
      this.deleteCampaignMessage(id);
    },
    async deleteCampaignMessage(id) {
      try {
        await this.$store.dispatch('campaignMessages/delete', id);
        this.showAlert(this.$t('CAMPAIGN_MESSAGE.DELETE.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('CAMPAIGN_MESSAGE.DELETE.API.ERROR_MESSAGE'));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.button-wrapper {
  @apply flex justify-end pb-2.5;
}
</style>
