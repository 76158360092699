<template>
  <div
    class="px-5 py-4 my-2 bg-white border shadow rounded-md dark:shadow-none dark:bg-slate-800 border-slate-50 dark:border-slate-800"
  >
    <div class="flex flex-col w-full">
      <div class="flex flex-row justify-between">
        <div>
          <p class="text-lg uppercase dark:text-slate-200">
            <b>{{ campaignMessage.title }}</b>
          </p>
        </div>
        <div class="flex flex-row flex-nowrap align-middle">
          <span
            class="rounded-md text-sm px-3 py-auto mx-2 text-center content-center text-black-900 dark:text-white"
            :class="{
              'bg-green-300 dark:bg-green-500': campaignMessage.active,
              'bg-red-300 dark:bg-red-700': !campaignMessage.active,
            }"
          >
            {{
              campaignMessage.active
                ? $t('CAMPAIGN_MESSAGE.LIST.TABLE_HEADER.ACTIVE')
                : $t('CAMPAIGN_MESSAGE.LIST.TABLE_HEADER.INACTIVE')
            }}
          </span>
          <omfy-button
            variant="mx-2 hover:bg-yellow-200 hover:text-black-900 dark:hover:bg-black-800"
            icon="edit"
            size="small"
            color-scheme="secondary"
            @click="$emit('edit', campaignMessage)"
          >
            {{ $t('CAMPAIGN_MESSAGE.LIST.BUTTONS.EDIT') }}
          </omfy-button>
          <omfy-button
            v-if="!campaignMessage.active"
            variant="mx-2 hover:bg-red-200 hover:text-black-900 dark:hover:bg-black-800"
            icon="dismiss-circle"
            size="small"
            color-scheme="danger"
            @click="$emit('delete', campaignMessage)"
          >
            {{ $t('CAMPAIGN_MESSAGE.LIST.BUTTONS.DELETE') }}
          </omfy-button>
        </div>
      </div>
      <div class="w-2/3 flex justify-start border-t border-black-400 py-1">
        <p class="prose dark:text-slate-200">{{ campaignMessage.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import OmfyButton from '../../../components/ui/OmfyButton.vue';

/**
 * @typedef {Object} CampaignMessage
 * @property {string} title - O título/nome do template.
 * @property {string} description - O texto que componhe o template.
 * @property {boolean} active - Se o Template está ativo.
 */

export default {
  components: { OmfyButton },
  props: {
    /**
     * @type {CampaignMessage}
     * @required
     */
    campaignMessage: {
      type: Object,
      required: true,
    },
  },

  computed: {},
};
</script>
