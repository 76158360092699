/* global axios */
import ApiClient from './ApiClient';

class CSATReportsAPI extends ApiClient {
  constructor() {
    super('csat_survey_responses', { accountScoped: true });
  }

  get({ page, from, to, user_ids, inbox_id, team_id, rating } = {}) {
    return axios.get(this.url, {
      params: {
        page,
        since: from,
        until: to,
        sort: '-created_at',
        user_ids,
        inbox_id,
        team_id,
        rating,
      },
    });
  }
//Implementacao geracao de relatorio analitico
  download({ type, from, to, user_ids, inbox_id, team_id, rating } = {}) {
    //console.log("dispatch from - "+from+" To- "+to+user_ids+" To- "+inbox_id+" To- "+team_id+" To- "+rating+" - "+type)
    if (type && type === 'analytics') {
      const url = window.location.origin + "/helper/v1/reports/generate";
      return axios.get(url, {
        params: {
          since: from,
          until: to,
          sort: '-created_at',
          user_ids,
        }
      });
    }
    return axios.get(`${this.url}/download`, {
      params: {
        since: from,
        until: to,
        sort: '-created_at',
        user_ids,
        inbox_id,
        team_id,
        rating,
      },
    });
  }

  getMetrics({ from, to, user_ids, inbox_id, team_id, rating } = {}) {
    // no ratings for metrics
    return axios.get(`${this.url}/metrics`, {
      params: { since: from, until: to, user_ids, inbox_id, team_id, rating },
    });
  }
}

export default new CSATReportsAPI();
