/* global axios */
import CacheEnabledApiClient from './CacheEnabledApiClient';

class CampaignMessagesAPI extends CacheEnabledApiClient {
  constructor() {
    super('campaign_messages', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  get cacheModelName() {
    return 'campaign_message';
  }

  getCampaignMessagesActives() {
    return axios.get(`${this.url}/actives`);
  }
}

export default new CampaignMessagesAPI();
