<template>
  <div class="flex-1 overflow-auto">
    <campaign-message />
  </div>
</template>

<script>
import CampaignMessage from './CampaignMessage.vue';

export default {
  components: {
    CampaignMessage,
  },
  computed: {},
  mounted() {
    this.$store.dispatch('campaignMessages/get');
  },
  methods: {},
};
</script>
