<template>
  <header
    class="bg-white dark:bg-slate-900 border-b border-slate-50 dark:border-slate-800"
  >
    <div class="flex justify-between w-full py-2 pl-4">
      <div class="flex items-center justify-center max-w-full min-w-[6.25rem]">
        <omfy-sidemenu-icon />
        <h1
          class="m-0 text-xl text-slate-900 dark:text-slate-100 overflow-hidden whitespace-nowrap text-ellipsis my-0 mx-2"
        >
          {{ headerTitle }}
        </h1>
      </div>
      <omfy-button
        color-scheme="success"
        icon="add-circle"
        @click="openAddPopup"
      >
        {{ buttonText }}
      </omfy-button>
      <omfy-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
        <add-campaign-message @on-close="hideAddPopup" />
      </omfy-modal>
    </div>
  </header>
</template>

<script>
import adminMixin from 'dashboard/mixins/isAdmin';
import AddCampaignMessage from './AddCampaignMessage.vue';

export default {
  components: {
    AddCampaignMessage,
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCreateModal: false,
      showAddPopup: false,
    };
  },
  computed: {
    buttonText() {
      return this.$t('CAMPAIGN_MESSAGE.HEADER_BTN_TXT.TITLE');
    },
  },
  methods: {
    toggleCreate() {
      this.$emit('on-toggle-create');
    },
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
